import Link from 'next/link'
import { useTranslation } from 'react-i18next'
import useSWR from 'swr'

import type { SubtypeItem } from 'core/remodel/types/belongingSummary'
import { Currency } from 'core/remodel/types/common'
import { AssetType } from 'core/remodel/types/enums'
import { defaultPreferences } from 'core/remodel/types/user'
import { fetchCurrentPreferences, userQuery } from '@/api/AccountService'
import { commonQuery, fetchAttachmentIv, fetchThumbnailUrl } from '@/api/CommonService'
import {
  listPathnameMap,
  placeholderImageMap,
  placeholderImageSizes,
  placeholderImageSrcSetMap,
  subtypeOptionMap
} from '@/constants/assets'
import { isValidSubtype } from '@/utils/checker'
import { cn } from '@/utils/classnames'
import { formatNumber, formatSymbol } from '@/utils/formatter'
import { ImageSizes } from '@/utils/imageTools'
import useIsMobile from '@/hooks/useIsMobile'
import { useAuthStore } from '@/store/authStore'
import TruncatedText from '@/components/base/TruncatedText'
import { Image } from '@/components/Image'
import { PrivacyField } from '@/components/PrivacyField'

export interface CollectableItemProps extends SubtypeItem {
  assetType: AssetType
  isEmptyState?: boolean
  mainImage?: { assetId: string; imageId?: string }
}

export function CollectableItem({
  assetType,
  label,
  itemNumber,
  value,
  mainImage,
  isEmptyState,
  assetNumber
}: CollectableItemProps) {
  const { t } = useTranslation()
  const database = useAuthStore((state) => state.database)
  const { data: imageUrl } = useSWR(
    mainImage?.imageId && [commonQuery.thumbnailUrl, mainImage.assetId, mainImage.imageId, ImageSizes.Large],
    fetchThumbnailUrl(database!)
  )
  const { data: base64IV } = useSWR(
    mainImage?.imageId && [commonQuery.attachmentIV, assetType, mainImage.assetId, mainImage.imageId],
    fetchAttachmentIv(database!)
  )
  const { data: preferences = defaultPreferences } = useSWR(
    [userQuery.currentPreferences],
    fetchCurrentPreferences(database!)
  )

  const validSubtypeCheck = isValidSubtype(assetType, label)
  const isMobile = useIsMobile()
  const currencyValue = isMobile
    ? formatSymbol(value.value, value.currency as Currency)
    : formatNumber(value.value, preferences.numberFormat, {
        digits: 0
      })
  return (
    <Link
      href={{ pathname: listPathnameMap[assetType], query: validSubtypeCheck ? { subType: label } : null }}
      className={cn(isEmptyState && 'pointer-events-none')}
      aria-disabled={isEmptyState}
      tabIndex={isEmptyState ? -1 : undefined}
    >
      <div
        className={cn(
          'group relative h-[190px] w-full overflow-hidden rounded bg-grey/20',
          isEmptyState && 'contrast-75 grayscale'
        )}
      >
        <Image
          className={'object-contain transition-transform duration-500 ease-out group-hover:scale-110'}
          src={imageUrl}
          base64={base64IV}
          alt={label}
          placeholder={placeholderImageMap[assetType]}
          srcSet={placeholderImageSrcSetMap[assetType]}
          sizes={placeholderImageSizes}
          fill={true}
        />
        <TruncatedText
          className={'absolute right-0 top-0 m-2 rounded bg-background px-2 py-0.5 text-xs font-medium text-white'}
          tip={t(`collectables:Field.${assetType}Number`, { count: itemNumber, type: label })}
        >
          {formatNumber(itemNumber, preferences.numberFormat, { digits: 0 })}
        </TruncatedText>
        <div className={cn('absolute inset-x-0 bottom-0 space-y-1 bg-white/60 p-2 backdrop-blur-sm')}>
          <div className={'flex items-center justify-between'}>
            <span className={'text-sm text-text'}>
              {t(`AssetTypeOptions.${label}`, t(`${subtypeOptionMap[assetType]}${label}`, label))}
            </span>
            <TruncatedText
              className={'rounded bg-background px-2 py-0.5 text-xs font-medium text-white'}
              tip={t(`collectables:Field.${assetType}AssetNumber`, { count: assetNumber, type: label })}
            >
              {formatNumber(assetNumber, preferences.numberFormat, { digits: 0 })}
            </TruncatedText>
          </div>
          <p className={'font-medium text-text'}>
            <PrivacyField>{currencyValue}</PrivacyField>
          </p>
        </div>
      </div>
    </Link>
  )
}
