import { Children, useCallback, useEffect, useMemo, useState } from 'react'
import { useAnimate } from 'framer-motion'
import { ChevronLeft, ChevronRight } from 'lucide-react'

import { cn } from '@/utils/classnames'
import { Button } from '@/components/base'

const DEFAULT_GAP = 12

interface FixedSizeCarouselProps extends React.HTMLAttributes<HTMLDivElement> {
  cardWidth: number
  containerClassName?: string
  gap?: number
  defaultPage?: number
}

export function FixedSizeCarousel({
  containerClassName,
  gap = DEFAULT_GAP,
  className,
  style,
  children,
  cardWidth,
  defaultPage = 0,
  ...props
}: FixedSizeCarouselProps) {
  const [scope, animate] = useAnimate<HTMLDivElement>()
  const [containerWidth, setContainerWidth] = useState(0)
  const [page, setPage] = useState(defaultPage)
  const total = Children.count(children)
  const count = useMemo(() => Math.floor((containerWidth + gap) / (cardWidth + gap)), [containerWidth, gap, cardWidth])

  const maxPage = useMemo(() => {
    return total - count >= 0 ? total - count : 0
  }, [total, count])

  const prev = useCallback(() => {
    setPage((prev) => (prev === 0 ? prev : prev - 1))
  }, [])

  const next = useCallback(() => {
    setPage((prev) => (prev === maxPage ? prev : prev + 1))
  }, [maxPage])

  useEffect(() => {
    if (page > maxPage) setPage(maxPage)
  }, [maxPage, page])

  useEffect(() => {
    const offsetPage = cardWidth + gap
    const offsetOfLastPage = (cardWidth + gap) * total - gap - containerWidth

    const isLastPage = page === maxPage
    const isFirstPage = page === 0
    const x = isLastPage && !isFirstPage ? -1 * offsetOfLastPage : -1 * offsetPage * page

    animate(scope.current, { x }, { type: 'tween', ease: 'easeOut' })
  }, [animate, containerWidth, gap, page, scope, cardWidth, maxPage, total])

  useEffect(() => {
    const observer = new ResizeObserver((entries) => {
      const entry = entries[0]
      setContainerWidth(entry.contentRect.width)
    })
    observer.observe(scope.current)
    return () => observer.disconnect()
  }, [scope])

  return (
    <div className={cn('relative', containerClassName)}>
      <div className={'overflow-hidden'}>
        <div ref={scope} className={cn('flex', className)} style={{ gap, ...style }} {...props}>
          {children}
        </div>
      </div>
      <Button
        className={cn('absolute -left-4 top-1/2 -translate-y-1/2 rounded-full bg-white p-1 text-primary shadow-lg', {
          hidden: page === 0
        })}
        onClick={prev}
      >
        <ChevronLeft />
        <span className={'sr-only'}>{'Next Slide'}</span>
      </Button>
      <Button
        className={cn('absolute -right-4 top-1/2 -translate-y-1/2 rounded-full bg-white p-1 text-primary shadow-lg', {
          hidden: page === maxPage
        })}
        onClick={next}
      >
        <ChevronRight />
        <span className={'sr-only'}>{'Prev Slide'}</span>
      </Button>
    </div>
  )
}
